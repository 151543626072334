import FormComponent from 'components/Form/Form';
import toast from 'components/toast';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from 'styles/classes.module.scss'
import { auditTypes, projectTypes } from './project.constant';
import { isArray } from 'lodash';
import classNames from 'classnames';
import ProjectComponent from './ProjectComponent';
import { useApi } from 'hooks/useApi';

const ProjectCreate = () => {
	const { createProject } = useApi()
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const [similarTo, setSimilarTo] = useState()

	const create = async (infos) => {
		setLoading(true)
		try {
			infos.type = Number(infos.type)
			if (infos.auditType)
				infos.auditType = Object.entries(auditTypes).find(([key, value]) => value === Number(infos.auditType))[0]
			const project = await createProject(infos)
			if (isArray(project)) {
				setSimilarTo(project[0])
				setLoading(false)
				return toast.error('Il existe déjà un audit similaire.')
			}
			toast.success('Projet créé.')
			setLoading(false)
			navigate('/project/' + project.id)
		} catch (error) {
			setLoading(false)
			console.log('error.message', error.message)
			toast.error('Erreur durant la création du projet: ' + error.message)
		}
	}

	const inputs = useMemo(() => [
		{
			type: 'select',
			name: 'type',
			label: 'Type de projet',
			required: true,
			silentRequired: true,
			value: String(projectTypes.AUDIT),
			options: Object
				.entries(projectTypes)
				.map(([projectType, index]) => ({
					key: String(index),
					value: String(index),
					text: projectType
				})),
		},
		{
			type: 'select',
			name: 'auditType',
			label: 'Type d\'audit',
			options: Object
				.entries(auditTypes)
				.map(([projectType, index]) => ({
					key: String(index),
					value: String(index),
					text: projectType
				})),
		},
		{
			type: 'text',
			name: 'cp',
			label: 'Code postal',
			required: true,
			silentRequired: true
		},
		{
			type: 'text',
			name: 'ticketKey',
			label: 'Clé ticket',
		},
		{
			type: 'text',
			name: 'name',
			label: 'Nom du client',
			required: true,
			silentRequired: true,
		},
		{
			type: 'datetime-local',
			name: 'createdDate',
			label: 'Date de création',
		},
		{
			type: 'datetime-local',
			name: 'auditDate',
			label: 'Date d\'audit',
			required: true,
			silentRequired: true
		},
		{
			type: 'number',
			name: 'priceAuditor',
			value: 0,
			label: 'Prix auditeur HT',
		},
		{
			type: 'number',
			name: 'priceInstallator',
			value: 0,
			label: 'Prix installateur HT',
		},
		{
			type: 'number',
			name: 'priceEngineer',
			value: 0,
			label: 'Prix ingénieur HT',
		},
		{
			type: 'number',
			name: 'kumac',
			value: 0,
			label: 'Kumac',
		}, {
			type: 'textArea',
			name: 'notes',
			value: '',
			label: 'Notes',
		},
	], [])


	return (
		<div className={classes.marginAuto}>
			<div className={classNames(classes.flex, classes.flexWrap, classes.gap4)}>
				<div>
					<h2>Création du projet</h2>
					<FormComponent
						loading={loading}
						inputs={inputs}
						sendForm={create}
						centered={true}
						responsive={false}
						segment={false}
					/>
				</div>
				{similarTo && <ProjectComponent similarTo {...similarTo} />}
			</div>

		</div>
	);
};

export default ProjectCreate;