import FormComponent from 'components/Form/Form';
import toast from 'components/toast';
import { objectToFormData } from 'helpers';
import React, { useCallback, useMemo, useState } from 'react';
import classes from 'styles/classes.module.scss'
import { projectTypes } from './project.constant';
import { useApi } from 'hooks/useApi';
import { useNavigate } from 'react-router-dom';

const filesTypes = {
	PDF: "application/pdf",
	ZIP: "application/zip",
	CSV: "text/csv",
	PNG: "image/png",
	JPG: "image/jpeg",
	JPEG: "image/jpeg",
	GIF: "image/gif",
	SVG: "image/svg+xml",
	TXT: "text/plain",
	HTML: "text/html",
	XML: "application/xml",
	JSON: "application/json",
	XLS: [
		"application/vnd.ms-excel",
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		"application/msexcel",
		"application/x-msexcel",
		"application/x-ms-excel",
		"application/x-excel",
	],
}
const UploadCsv = () => {
	const navigate = useNavigate()
	const { sendCsvFile, sendCsvFileAudits } = useApi()
	const [loading, setLoading] = useState(false)
	const inputs = useMemo(() => [
		{
			type: 'select',
			name: 'type',
			label: 'Type de projet',
			required: true,
			silentRequired: true,
			value: String(projectTypes.PROJECT),
			options: Object
				.entries(projectTypes)
				.map(([projectType, index]) => ({
					key: String(index),
					value: String(index),
					text: projectType
				})),
		},
		{
			type: 'file',
			name: 'csv',
			preview: true,
			label: 'Fichier CSV',
			required: true,
			silentRequired: true,
		}
	], [])

	const sendForm = useCallback(async (values) => {
		setLoading(true)
		try {
			values.type = Number(values.type)

			if (!values.csv ||
				(values.csv.type !== filesTypes.CSV && !filesTypes.XLS.includes(values.csv.type))
			) {
				setLoading(false)
				return toast.error("Vos fichiers ne sont pas du bon type")
			}

			const { id } = await (values.type === projectTypes.PROJECT ?
				sendCsvFile : sendCsvFileAudits
			)(objectToFormData(values))
			setLoading(false)
			toast.success('Le fichier csv a correctement été exploité.')
			return navigate('/insertedProjects/' + id)// onSent(res)
		} catch (e) {
			setLoading(false)
			console.error(e)
			toast.error('Erreur durant la lecture du fichier csv téléchargé. Vérifiez qu\'il s\'agit bien du bon fichier.')
		}
	}, [navigate, sendCsvFile, sendCsvFileAudits])
	return (
		<div className={classes.marginAuto}>
			<h2>Chargement du CSV</h2>
			<FormComponent
				inputs={inputs}
				sendForm={sendForm}
				centered={true}
				// responsive={false}
				segment={false}
				loading={loading}
				messages={{ submit: 'Charger le CSV' }}
			/>
		</div>

	);
};

export default UploadCsv;